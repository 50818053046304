/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import Web3ConsultingServices from './web3ConsultingServices'

const AnimatedBox = animated(Box)

const Web3Consulting = () => {
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box sx={{ my: 4, px: [1, 4, 4] }}>
        <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
          <Box sx={{ mx: 2, textAlign: 'center' }}>
            <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>Consulting</Styled.p>
          </Box>
          <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
        </Flex>

        <AnimatedBox
          style={subtitleFade}
          sx={{
            width: ['100%', '75%', '75%'],
            mx: 'auto',
            pt: [3, 4, 4],
            pb: [2, 2, 2],
            px: [1, 3, 6],
            textAlign: 'center',
          }}
        >
          <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>We help individuals and businesses navigate the Web3 ecosystem and integrate blockchain technology.</Styled.p>
          <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>We can help with:</Styled.p>
        </AnimatedBox>

        <Web3ConsultingServices />
      </Box>
    </Fragment>
  )
}

export default Web3Consulting
