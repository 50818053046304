/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Fragment } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import { Waypoint } from 'react-waypoint'
import ConsultingServices from './consultingServices'

const AnimatedBox = animated(Box)

const Web3ConsultingServices = () => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })

  return (
    <Fragment>
      <Waypoint
        bottomOffset="10%"
        onEnter={() => {
          if (!active) setActive(true)
        }}
      />
      <Box sx={{ mt: 4 }}>
        <AnimatedBox
          style={headingFade}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            m: [1, 3, 3, 3],
          }}
        >
          <ConsultingServices />
        </AnimatedBox>
      </Box>
    </Fragment>
  )
}

export default Web3ConsultingServices
