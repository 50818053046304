/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)

const Web2vWeb3 = () => {
  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 250,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box sx={{ my: 4, px: [1, 4, 4] }}>
        <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
          <Box sx={{ mx: 2, textAlign: 'center' }}>
            <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>Web2 vs Web3</Styled.p>
          </Box>
          <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
        </Flex>

        <AnimatedBox
          style={subtitleFade}
          sx={{
            width: ['100%', '75%', '75%'],
            mx: 'auto',
            pt: [3, 4, 4],
            pb: [3, 4, 4],
            px: [1, 3, 6],
            textAlign: 'center',
          }}
        >
          <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
            Web2 is the internet as we know it today, where data is hosted on data servers owned by large companies such as Twitter, Google, and Facebook (YouTube, Instagram etc.). Typically they provide a “free” service (websites and apps) for us to use in exchange for our data, which they then
            control on their servers.
          </Styled.p>
          <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
            Web3 is the next iteration of the internet where no single entity controls our data. It's often referred to as the 'open internet'. It runs on decentralised networks powered by blockchain technology. The data is decentralised because a blockchain is a peer-to-peer network of computers,
            each having a copy of the data, rather than a single entity being the only one with a copy of the data.
          </Styled.p>
          <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
            This new Web3 ecosystem is rapidly evolving, and is presenting new ways for us to build and engage with communities. Platforms and apps are being built that share ownership amongst users based on their contributions to the platform. What if Facebook gave you an ownership stake based on
            the content you posted? Web3 is making this a reality.
          </Styled.p>
          <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
            This next iteration of the web encompasses such things as NFTs, tokens, DAOs (decentralised autonomous organisations), DeFi (decentralised finance), and virtual worlds, with blockchain technology as the backbone.
          </Styled.p>
          <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
            This is a new frontier, and the Web3 ecosystem is rapidly evolving. It’s not perfect, and we’re always learning, and improving the application of blockchain technology, and the protocols and consumer apps built on blockchains. As we progressively decentralise, we realise there are use
            cases for centralised apps too. It's not about replacing Web2 with Web3, but rather working out how best to utilise the best of both.
          </Styled.p>
          <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
            We believe that blockchain technology is a game changer. We’re excited to explore the possibilities and opportunities that Web3 holds, and want to be one of many bridges for those who want to participate in the new open web.
          </Styled.p>
        </AnimatedBox>
      </Box>
    </Fragment>
  )
}

export default Web2vWeb3
