/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import Container from './container'
import Wave from './wave'
import BookACall from './bookACall'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)

const Web3EducationTest = () => {
  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 250,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
          // mt: '-2px',
        }}
      >
        <Wave wave="aboutApproach" transformFrom="bottom" height="auto" />
        <Container>
          <Box sx={{ mt: 4, mb: 0, px: [1, 4, 4] }}>
            <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
              <Box sx={{ mx: 2, textAlign: 'center' }}>
                <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>Web3 Education</Styled.p>
              </Box>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
            </Flex>

            <AnimatedBox
              style={subtitleFade}
              sx={{
                width: ['100%', '75%', '75%'],
                mx: 'auto',
                pt: [3, 4, 4],
                pb: [1, 1, 1],
                px: [1, 3, 6],
                textAlign: 'center',
              }}
            >
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
                We empower individuals to participate in Web3 through IRL (in real life) education, in a local community context. We are missing social context in our Web3 interactions, and by creating that social context, we can together build a trusted environment where people feel comfortable and
                secure in their Web3 journey.
              </Styled.p>
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
                We believe educating an individual not only empowers them, but also benefits their communities in turn as they share their knowledge with others in the community, their employers, or their own businesses.
              </Styled.p>
            </AnimatedBox>
            <AnimatedFlex style={headingFade} sx={{ mt: 4, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 2, textAlign: 'center', maxWidth: ['55%', '50%', '50%', '50%'] }}>
                <Styled.p sx={{ fontSize: 2, variant: 'text.form', color: 'headingSecondary' }}>Like to know more about our Web3 IRL meetups in Perth and Brisbane?</Styled.p>
              </Box>
            </AnimatedFlex>
            <AnimatedFlex style={headingFade} sx={{ my: 3, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 2, textAlign: 'center', maxWidth: ['55%', '50%', '50%', '60%'] }}>
                <Styled.p sx={{ fontSize: 2, variant: 'text.form', color: 'headingSecondary' }}> Book an introductory call with</Styled.p>
              </Box>
            </AnimatedFlex>
            <BookACall />
          </Box>
        </Container>
      </Box>
    </Fragment>
  )
}

export default Web3EducationTest
