/** @jsx jsx */
import { jsx, Styled, Flex, useThemeUI } from 'theme-ui'
import NftIcon from '../../static/images/NFT-icon.webp'
import { ConsultingLogoAnimationA, ConsultingLogoAnimationB } from './consultingLogoAnimation'

const servicesConsulting = [
  {
    slug: 'smart-contracts',
    icon: NftIcon,
    iconName: 'Smart Contracts',
    iconWidth: '42px',
    animationStyle: 'startUp',
    consultingService: 'Writing smart contracts and deploying them to a blockchain',
  },
  {
    slug: 'ui',
    icon: NftIcon,
    iconName: 'User Interface',
    iconWidth: '42px',
    animationStyle: 'startDown',
    consultingService: 'Building frontend interfaces (eg: websites) for your community to interact with on-chain contracts and data',
  },
  {
    slug: 'architecture',
    icon: NftIcon,
    iconName: 'Architecture',
    iconWidth: '42px',
    animationStyle: 'startUp',
    consultingService: 'Evaluating architecture based on your objectives',
  },
  {
    slug: 'nfts',
    icon: NftIcon,
    iconName: 'NFTs',
    iconWidth: '42px',
    animationStyle: 'startDown',
    consultingService: 'NFTs and utility tokens',
  },
  {
    slug: 'community',
    icon: NftIcon,
    iconName: 'Community Building & Loyalty',
    iconWidth: '42px',
    animationStyle: 'startUp',
    consultingService: 'Community building & loyalty',
  },
  {
    slug: 'identity',
    icon: NftIcon,
    iconName: 'Identity',
    iconWidth: '42px',
    animationStyle: 'startDown',
    consultingService: 'On-chain identity',
  },
  {
    slug: 'web3-education',
    icon: NftIcon,
    iconName: 'education',
    iconWidth: '42px',
    animationStyle: 'startUp',
    consultingService: 'Web3 education',
  },
]

function ConsultingServices() {
  const context = useThemeUI()
  const { theme } = context

  return (
    <Flex sx={{ pt: 0, pb: 2, width: ['85%', '75%', '60%', '60%'] }}>
      <ul
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          listStyle: 'none',
          m: '0',
          p: '0',
        }}
      >
        {servicesConsulting.map((service, i) => (
          <div index={i} sx={{ width: '100%' }}>
            <li
              sx={{
                display: 'flex',
                alignItems: 'center',
                my: '2',
                p: '0',
              }}
            >
              {service.animationStyle === 'startUp' && (
                <Flex sx={{ width: '10%', height: 'inherit', mx: 1, alignItems: 'center', justifyContent: 'center' }}>
                  <ConsultingLogoAnimationA color={`${theme.colors.text}`} slug={service.slug} />
                </Flex>
              )}
              {service.animationStyle === 'startDown' && (
                <Flex sx={{ width: '10%', height: 'inherit', mx: 1, alignItems: 'center', justifyContent: 'center' }}>
                  <ConsultingLogoAnimationB color={`${theme.colors.text}`} slug={service.slug} />
                </Flex>
              )}
              <Flex sx={{ mx: 3, width: '90%' }}>
                <Styled.p sx={{ fontSize: '3', variant: 'text.consultingServices', color: 'text' }}>{service.consultingService}</Styled.p>
              </Flex>
            </li>
          </div>
        ))}
      </ul>
    </Flex>
  )
}

export default ConsultingServices
