/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useToggle } from '../hooks'

const AnimatedBox = animated(Box)

export const ConsultingLogoAnimationA = ({ slug = 'web3-education', color }) => {
  const { isToggled: float, toggle: setFloat } = useToggle(false)

  const styleA = useSpring({
    from: { transform: 'translate(0px, 0px)' },
    to: { transform: `translate(0px, 6px)` },
    config: { duration: 7500 },
    reverse: float,
    onRest: () => setFloat(),
    reset: float,
  })

  return (
    <Fragment>
      <AnimatedBox style={styleA}>
        {slug === 'smart-contracts' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'ui' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'architecture' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'nfts' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'community' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'identity' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'web3-education' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
      </AnimatedBox>
    </Fragment>
  )
}

export const ConsultingLogoAnimationB = ({ color, slug = 'web3-education' }) => {
  const { isToggled: float, toggle: setFloat } = useToggle(false)

  const styleB = useSpring({
    from: { transform: 'translate(0px, 6px)' },
    to: { transform: `translate(0px, 0px)` },
    config: { duration: 7500 },
    reverse: float,
    onRest: () => setFloat(),
    reset: float,
  })

  return (
    <Fragment>
      <AnimatedBox style={styleB}>
        {slug === 'smart-contracts' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'ui' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'architecture' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'nfts' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'community' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'identity' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
        {slug === 'web3-education' && <FontAwesomeIcon icon={faChevronRight} color={color} size="1x" />}
      </AnimatedBox>
    </Fragment>
  )
}
