/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Fragment } from 'react'
import Container from './container'
import Web2vWeb3 from './web2Vweb3'
import Web3Consulting from './web3Consulting'

const Web3Body = () => {
  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Container>
          <Web2vWeb3 />
          <Web3Consulting />
          {/* <Web3Education /> */}
        </Container>
      </Box>
    </Fragment>
  )
}

export default Web3Body
