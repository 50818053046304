/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import SEO from './SEO/seo'
import Layout from './layout'
import { useLocation } from '@reach/router'
import Web3Hero from './web3Hero'
import Web3Body from './web3Body'
import Web3Education from './web3Education'
import ContactSection from './contactSection'

import { Fragment } from 'react'

const Web3 = () => {
  const { pathname } = useLocation()
  return (
    <Fragment>
      <Layout location={pathname}>
        <SEO title="Web3" description="Welcome to the next iteration of the internet powered by blockchain technology." />
        <Flex
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Web3Hero />
          <Web3Body />
          {/* <Web3Education /> */}
          <ContactSection variant="contact" heading="Join us in Web3" buttonLabel="Start a conversation" linkToFirstButton="/contact/" secondButton={true} secondLabel="See our work" variantSecondButton="outline" linkToSecondButton="/work/" withWave={true} />
        </Flex>
      </Layout>
    </Fragment>
  )
}

export default Web3
